import React from 'react'

export default function Logo({color}) {
   return (
      <svg width="300px" height="300px" viewBox="0 0 300 300" enable-background="new 0 0 300 300">
         <path fill={color} d="M150,20L37.416,85v130L150,280l112.584-65V85L150,20z M85.7,85.753l35.99,35.99l35.356-35.356l14.142,14.142
         l-49.498,49.497L71.559,99.894L85.7,85.753z M178.283,220.71l-14.142-14.143l14.142-14.142l14.143,14.142L178.283,220.71z
         M214.275,214.272l-35.99-35.99l-35.357,35.356l-14.142-14.142L178.284,150l50.132,50.132L214.275,214.272z M234.853,192.427
         l-56.569-56.568l-56.567,56.568l-70.711-70.711l14.142-14.142l56.569,56.569l56.568-56.569l10.12,10.12l4.022,4.022l56.568,56.568
         L234.853,192.427z"/>
      </svg>
   )
}



